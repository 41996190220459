import React, { useEffect, useRef, useState } from "react"

const useIntersect = (
  root?: Element,
  rootMargin?: string,
  threshold?: number
) => {
  const ref = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  let options = {
    root: root || null,
    rootMargin: rootMargin || "0px",
    threshold: threshold || 0,
  }

  useEffect(() => {
    let callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      })
    }

    let observer = new IntersectionObserver(callback, options)

    if (ref.current) {
      observer.observe(ref.current)
    }
  }, [])

  return [isVisible, ref]
}

export default useIntersect
