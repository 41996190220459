import React from "react"
import { graphql } from "gatsby"
import useIntersect from "../hooks/useIntersect"
import Layout from "../components/layout/layout"

import { Disqus } from "gatsby-plugin-disqus"

import "../styles/prism.custom.scss"

import "./blogposttemplate.scss"

export default function Template({ data }) {
  const [isVisible, intersect] = useIntersect()
  const { markdownRemark, site } = data
  const { frontmatter, html } = markdownRemark

  const formatDate = date => {
    const dateObj = new Date(date)
    return `${dateObj.getFullYear()}-${dateObj.getMonth() +
      1}-${dateObj.getDate()}`
  }

  const postUrl = `${site.siteMetadata.siteUrl}${frontmatter.path}`

  const postData = {
    "@context": "https://schema.org",
    "@type": "Article",
    publisher: {
      "@type": "Organization",
      name: site.siteMetadata.title,
      logo: `${site.siteMetadata.siteUrl}/icons/icon-48x48.png`,
    },
    author: {
      "@type": "Person",
      name: site.siteMetadata.author,
      image: {
        "@type": "ImageObject",
        url: "//s.gravatar.com/avatar/ca001a5c9f3aa93b64e087f0fdfa39ca?s=80",
        width: 250,
        height: 250,
      },
      url: `${site.siteMetadata.siteUrl}/about`,
      sameAs: [],
    },
    headline: frontmatter.title,
    url: postUrl,
    datePublished: formatDate(frontmatter.date),
    dateModified: formatDate(frontmatter.date),
    keywords: frontmatter.tags.join(", "),
    image: {
      "@type": "ImageObject",
      url: `${site.siteMetadata.siteUrl}/icons/icon-48x48.png`,
      width: 960,
      height: 642,
    },
    description: frontmatter.strapline,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": site.siteMetadata.siteUrl,
    },
  }

  const disqusConfig = {
    url: postUrl,
    identifier: frontmatter.id,
    title: frontmatter.title,
  }

  return (
    <Layout title={frontmatter.title} useSchema="false" linkedData={postData}>
      <section className="blog-post">
        <h1>{frontmatter.title}</h1>
        <time
          className="blog-post__date"
          dateTime={new Date(frontmatter.date).toISOString()}
        >
          {frontmatter.date}
        </time>
        <div
          className="blog-post__content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="addthis_inline_share_toolbox"></div>
        <div ref={intersect} className="blog-post__comments">
          <h2>Comments</h2>
          {isVisible && (
            <>
              <script
                type="text/javascript"
                src="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a5488d7fa39f458"
              ></script>
              <Disqus config={disqusConfig} />
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        strapline
      }
    }
    site {
      ...siteMetaDataFragment
    }
  }
`
